// 计算出时间戳的具体数据：比如将85400转化为 n时n分n秒
export function formateTimeStamp(timeStamp) {
  var day;
  var hour;
  var min;
  var seconds;

  day = parseInt(timeStamp / (60 * 60 * 24)); // 计算整天数
  var afterDay = timeStamp - day * 60 * 60 * 24; // 取得算出天数后剩余的秒数
  hour = parseInt(afterDay / (60 * 60)); // 计算整数小时数
  var afterHour = timeStamp - day * 60 * 60 * 24 - hour * 60 * 60; // 取得算出小时数后剩余的秒数
  min = parseInt(afterHour / 60); // 计算整数分
  seconds = parseInt(
    timeStamp - day * 60 * 60 * 24 - hour * 60 * 60 - min * 60,
  ); // 取得算出分后剩余的秒数

  if (hour < 10) {
    hour = '0' + hour;
  }

  if (min < 10) {
    min = '0' + min;
  }

  if (seconds < 10) {
    seconds = '0' + seconds;
  }

  const restTime = {
    day: day,
    hour: hour,
    min: min,
    seconds: seconds,
  };
  return restTime;
}

export function isSafari() {
  var ua = navigator.userAgent.toLowerCase();
  if (
    ua.indexOf('applewebkit') > -1 &&
    ua.indexOf('mobile') > -1 &&
    ua.indexOf('safari') > -1 &&
    ua.indexOf('linux') === -1 &&
    ua.indexOf('android') === -1 &&
    ua.indexOf('chrome') === -1 &&
    ua.indexOf('ios') === -1 &&
    ua.indexOf('browser') === -1 &&
    ua.indexOf('qqbrowse') === -1
  ) {
    return true;
  } else {
    return false;
  }
}
